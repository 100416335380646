export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      resource: 'page',
      action: 'read',
      pageTitle: 'Dashboard',
      breadcrumb: [
      ],
    },
  },
]
