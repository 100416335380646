import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import app from './app'
import auth from './auth'
import dashboard from './dashboard'
import users from './users'
import transaction from './transaction'
import logs from './logs'
import reports from './reports'
import settings from './settings'
import error from './error'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...app,
    ...auth,
    ...dashboard,
    ...users,
    ...transaction,
    ...logs,
    ...reports,
    ...settings,
    ...error,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  if (to.meta.breadcrumb)
    Vue.prototype.$http.get('user?' + new URLSearchParams({ name: to.meta.breadcrumb.map(b=>b.text).join(' > '), url: to.path }).toString()).then(res=>{}).catch(console.error);

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
