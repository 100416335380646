export default [
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/dashboard/Report.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'สรุปรายการ กำไร/ขาดทุน',
      breadcrumb: [
        {
          text: 'รายงานสรุป',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/member',
    name: 'report_member',
    component: () => import('@/views/report/report_member.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ภาพรวมสมาชิก',
      breadcrumb: [
        {
          text: 'รายงานสรุป',
          to: { name: 'report' },
        },
        {
          text: 'ภาพรวมสมาชิก',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/agent',
    name: 'report_agent',
    component: () => import('@/views/report/report_agent.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'สรุปเอเย่นต์',
      breadcrumb: [
        {
          text: 'รายงานสรุป',
          to: { name: 'report' },
        },
        {
          text: 'สรุปเอเย่นต์',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/promotion',
    name: 'report_promotion',
    component: () => import('@/views/report/report_promotion.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'สรุปโปรโมชั่น',
      breadcrumb: [
        {
          text: 'รายงานสรุป',
          to: { name: 'report' },
        },
        {
          text: 'สรุปโปรโมชั่น',
          active: true,
        },
      ],
    },
  },
]
