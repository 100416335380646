export default [
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/promotion/promotion.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ระบบจัดการโปรโมชั่น',
      breadcrumb: [
        {
          text: 'โปรโมชั่น',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promotion/promotion_form_create',
    name: 'promotion_form_create',
    component: () => import('@/views/promotion/promotion_form_create.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'promotion',
      pageTitle: 'ระบบจัดการโปรโมชั่น',
      breadcrumb: [
        {
          text: 'โปรโมชั่น',
          to: { name: 'promotion' },
        },
        {
          text: 'เพิ่มโปรโมชั่น',
          active: true,
        },
      ],
    },
  },
  {
    path: '/promotion/promotion_form_update/:id',
    name: 'promotion_form_update',
    component: () => import('@/views/promotion/promotion_form_update.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'promotion',
      pageTitle: 'ระบบจัดการโปรโมชั่น',
      breadcrumb: [
        {
          text: 'โปรโมชั่น',
          to: { name: 'promotion' },
        },
        {
          text: 'แก้ไขโปรโมชั่น',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/news.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ระบบจัดการประกาศ',
      breadcrumb: [
        {
          text: 'ประกาศ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news/news_form_create',
    name: 'news_form_create',
    component: () => import('@/views/news/news_form_create.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'news',
      pageTitle: 'ระบบจัดการประกาศ',
      breadcrumb: [
        {
          text: 'ประกาศ',
          to: { name: 'news' },
        },
        {
          text: 'เพิ่มประกาศ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/news/news_form_update/:id',
    name: 'news_form_update',
    component: () => import('@/views/news/news_form_update.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'news',
      pageTitle: 'ระบบจัดการประกาศ',
      breadcrumb: [
        {
          text: 'ประกาศ',
          to: { name: 'news' },
        },
        {
          text: 'แก้ไขประกาศ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('@/views/bank/bank.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ระบบจัดการธนาคาร',
      breadcrumb: [
        {
          text: 'ธนาคาร',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bank/bank_form_create',
    name: 'bank_form_create',
    component: () => import('@/views/bank/bank_form_create.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'bank',
      pageTitle: 'ระบบจัดการธนาคาร',
      breadcrumb: [
        {
          text: 'ธนาคาร',
          to: { name: 'bank' },
        },
        {
          text: 'เพิ่มธนาคาร',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bank/bank_form_update/:id',
    name: 'bank_form_update',
    component: () => import('@/views/bank/bank_form_update.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'bank',
      pageTitle: 'ระบบจัดการธนาคาร',
      breadcrumb: [
        {
          text: 'ธนาคาร',
          to: { name: 'bank' },
        },
        {
          text: 'แก้ไขธนาคาร',
          active: true,
        },
      ],
    },
  },
  {
    path: '/web_setting',
    name: 'web_setting',
    component: () => import('@/views/settings/web_setting.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
      pageTitle: 'ตั้งค่าระบบทั่วไป',
      breadcrumb: [
        {
          text: 'ข้อมูลระบบทั่วไป',
          active: true,
        },
      ],
    },
  },
  {
    path: '/auth_setting',
    name: 'auth_setting',
    component: () => import('@/views/settings/auth_setting.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
      pageTitle: 'พนักงาน',
      breadcrumb: [
        {
          text: 'จัดการพนักงาน',
          active: true,
        },
      ],
    },
  },
]
