export default [
  {
    path: '/bank/bank_app_scb',
    name: 'bank-app',
    component: () => import('@/views/bank/app/scb/bank.vue'),
    meta: {
      layout: 'full',
      resource: 'page',
      action: 'read',
      pageTitle: 'Bank APP',
    },
  },
  {
    path: '/bank/bank_app_scb/trans',
    name: 'bank-app-trans',
    component: () => import('@/views/bank/app/scb/bank.vue'),
    meta: {
      layout: 'full',
      resource: 'page',
      action: 'read',
      pageTitle: 'Bank APP',
    },
  },
]
