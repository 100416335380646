import Vue from 'vue'
import router from '@/router'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API || '/api/',
  // timeout: 1000,
  headers: {
  },
})

axiosIns.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401 || error.response.status === 422) {
    router.push({name:'logout'})
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
