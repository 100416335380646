export default [
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/user.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ระบบจัดการสมาชิก',
      breadcrumb: [
        {
          text: 'สมาชิก',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/user_form_update/:id',
    name: 'user_form_update',
    component: () => import('@/views/user/user_form_update.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'user',
      pageTitle: 'ระบบจัดการสมาชิก',
      breadcrumb: [
        {
          text: 'สมาชิก',
          to: { name: 'user' },
        },
        {
          text: 'แก้ไขสมาชิก',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent',
    name: 'agent',
    component: () => import('@/views/agent/agent.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ระบบจัดการเอเย่นต์',
      breadcrumb: [
        {
          text: 'เอเย่นต์',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/report_member_register/:id',
    name: 'agent_report_member_register',
    component: () => import('@/views/agent/agent_report_member_register.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'agent',
      pageTitle: 'ระบบจัดการเอเย่นต์',
      breadcrumb: [
        {
          text: 'เอเย่นต์',
          to: { name: 'agent' },
        },
        {
          text: 'ยูสเซอร์ภายใต้เอเย่นต์',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/report_member/:id',
    name: 'agent_report_member',
    component: () => import('@/views/agent/agent_report_member.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'agent',
      pageTitle: 'ระบบจัดการเอเย่นต์',
      breadcrumb: [
        {
          text: 'เอเย่นต์',
          to: { name: 'agent' },
        },
        {
          text: 'สรุปฝาก-ถอนยูส',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/report_winlost/:id',
    name: 'agent_report_winlost',
    component: () => import('@/views/agent/agent_report_winlost.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'agent',
      pageTitle: 'ระบบจัดการเอเย่นต์',
      breadcrumb: [
        {
          text: 'เอเย่นต์',
          to: { name: 'agent' },
        },
        {
          text: 'รายงานบัญชีแพ้ชนะ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/member/:id',
    name: 'agent_member',
    component: () => import('@/views/agent/agent_member.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'agent',
      pageTitle: 'ระบบจัดการเอเย่นต์',
      breadcrumb: [
        {
          text: 'เอเย่นต์',
          to: { name: 'agent' },
        },
        {
          text: 'ยูสของเอเย่น',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/commission/:id',
    name: 'agent_commission',
    component: () => import('@/views/agent/agent_commission.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'agent',
      pageTitle: 'ระบบจัดการเอเย่นต์',
      breadcrumb: [
        {
          text: 'เอเย่นต์',
          to: { name: 'agent' },
        },
        {
          text: 'รายงานคอมมิชชั่น',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/login/:id',
    name: 'agent_login',
    component: () => import('@/views/agent/agent_login.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      navActiveLink: 'agent',
      pageTitle: 'ระบบจัดการเอเย่นต์',
      breadcrumb: [
        {
          text: 'เอเย่นต์',
          to: { name: 'agent' },
        },
        {
          text: 'เข้าสู่ระบบ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ref',
    name: 'ref',
    component: () => import('@/views/ref/ref.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'แนะนำเพื่อน',
      breadcrumb: [
        {
          text: 'แนะนำเพื่อน',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bonus/ref',
    name: 'bonus_ref',
    component: () => import('@/views/ref/bonus_ref.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ระบบจัดการโบนัสยอดเทิร์นโอเวอร์',
      breadcrumb: [
        {
          text: 'โบนัสยอดเทิร์นโอเวอร์',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bonus/return_balance',
    name: 'bonus_return_balance',
    component: () => import('@/views/ref/bonus_return_balance.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ระบบจัดการคืนยอดเสีย',
      breadcrumb: [
        {
          text: 'คืนยอดเสีย',
          active: true,
        },
      ],
    },
  },
]
