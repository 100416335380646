export default [
  {
    path: '/log/DepositWithdraw',
    name: 'log_DepositWithdraw',
    component: () => import('@/views/log/log_DepositWithdraw.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบ Logs ฝาก-ถอน',
      breadcrumb: [
        {
          text: 'Logs ฝาก-ถอน',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log/Sms',
    name: 'log_Sms',
    component: () => import('@/views/log/log_Sms.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบ Logs SMS',
      breadcrumb: [
        {
          text: 'Logs SMS',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log/Wheel',
    name: 'log_Wheel',
    component: () => import('@/views/log/log_Wheel.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบ Logs วงล้อนำโชค',
      breadcrumb: [
        {
          text: 'Logs วงล้อนำโชค',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log/Reward',
    name: 'log_Reward',
    component: () => import('@/views/log/log_Reward.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบ Logs สะสมแต้ม',
      breadcrumb: [
        {
          text: 'Logs สะสมแต้ม',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log/Page',
    name: 'log_Page',
    component: () => import('@/views/log/log_Page.vue'),
    meta: {
      resource: 'user',
      action: 'read',
      pageTitle: 'ระบบ Logs เปิดหน้าเว็ป',
      breadcrumb: [
        {
          text: 'Logs เปิดหน้าเว็ป',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log/LineNotify',
    name: 'log_LineNotify',
    component: () => import('@/views/log/log_LineNotify.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบ Logs Line notify',
      breadcrumb: [
        {
          text: 'Logs Line notify',
          active: true,
        },
      ],
    },
  },
  {
    path: '/log/TrasferOut',
    name: 'log_TrasferOut',
    component: () => import('@/views/log/log_TransferOut.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
      pageTitle: 'ระบบ Logs โยกเงินออก',
      breadcrumb: [
        {
          text: 'Logs โยกเงินออก',
          active: true,
        },
      ],
    },
  },
]
