export default [
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import('@/views/deposit/deposit.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบจัดการฝากเงิน',
      breadcrumb: [
        {
          text: 'ฝากเงิน',
          active: true,
        },
      ],
    },
  },
  {
    path: '/deposit/deposit_form_detail/:id',
    name: 'deposit_form_detail',
    component: () => import('@/views/deposit/deposit_form_detail.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      navActiveLink: 'deposit',
      pageTitle: 'ระบบจัดการฝากเงิน',
      breadcrumb: [
        {
          text: 'ฝากเงิน',
          to: { name: 'deposit' },
        },
        {
          text: 'ตรวจสอบฝากเงิน',
          active: true,
        },
      ],
    },
  },
  {
    path: '/creditwait',
    name: 'creditwait',
    component: () => import('@/views/credit/creditwait.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบจัดการเครดิต (รอฝาก)',
      breadcrumb: [
        {
          text: 'เครดิต (รอฝาก)',
          active: true,
        },
      ],
    },
  },
  {
    path: '/creditwait/creditwait_form_detail/:id',
    name: 'creditwait_form_detail',
    component: () => import('@/views/credit/creditwait_form_detail.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      navActiveLink: 'creditwait',
      pageTitle: 'ระบบจัดการเครดิต (รอฝาก)',
      breadcrumb: [
        {
          text: 'เครดิต (รอฝาก)',
          to: { name: 'creditwait' },
        },
        {
          text: 'ตรวจสอบเครดิต (รอฝาก)',
          active: true,
        },
      ],
    },
  },
  {
    path: '/credit',
    name: 'credit',
    component: () => import('@/views/credit/credit.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบจัดการเครดิต',
      breadcrumb: [
        {
          text: 'เครดิต',
          active: true,
        },
      ],
    },
  },
  {
    path: '/credit/credit_form_detail/:id',
    name: 'credit_form_detail',
    component: () => import('@/views/credit/credit_form_detail.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      navActiveLink: 'credit',
      pageTitle: 'ระบบจัดการเครดิต',
      breadcrumb: [
        {
          text: 'เครดิต',
          to: { name: 'credit' },
        },
        {
          text: 'ตรวจสอบเครดิต',
          active: true,
        },
      ],
    },
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('@/views/withdraw/withdraw.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      pageTitle: 'ระบบจัดการถอนเงิน',
      breadcrumb: [
        {
          text: 'ถอนเงิน',
          active: true,
        },
      ],
    },
  },
  {
    path: '/withdraw/withdraw_form_detail/:id',
    name: 'withdraw_form_detail',
    component: () => import('@/views/withdraw/withdraw_form_detail.vue'),
    meta: {
      resource: 'credit',
      action: 'read',
      navActiveLink: 'withdraw',
      pageTitle: 'ระบบจัดการถอนเงิน',
      breadcrumb: [
        {
          text: 'ถอนเงิน',
          to: { name: 'withdraw' },
        },
        {
          text: 'ตรวจสอบถอนเงิน',
          active: true,
        },
      ],
    },
  },
  {
    path: '/TransferOut',
    name: 'TransferOut',
    component: () => import('@/views/withdraw/TransferOut.vue'),
    meta: {
      resource: 'admin',
      action: 'read',
      pageTitle: 'ระบบโยกเงินออก',
      breadcrumb: [
        {
          text: 'โยกเงินออก',
          active: true,
        },
      ],
    },
  },
]
